<!--
 * @Description: 商品分类三级分类列表页面
 * @Author: 小广
 * @Date: 2019-06-04 14:06:49
 * @LastEditors: 蒙川
 * @LastEditTime: 2019-08-02 18:11:33
 -->
<template>
  <div class="goodsCategoryThirdList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :extraParams="extraParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="返回" @click="backAction"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          label="三级分类名称"
          v-model="searchParams.categoryAlias"
        ></v-input>
        <v-input label="三级类目" v-model="searchParams.categoryName"></v-input>
        <v-select
          label="状态"
          v-model="searchParams.showStatus"
          :options="statusOps"
        ></v-select>
        <v-input
          label="操作人"
          v-model="searchParams.operatorUserName"
        ></v-input>
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          type="text"
          permission="update"
          @click="edit(scope.row)"
        ></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL } from './api'
import { statusOps } from './map'
import { createImgVNode } from 'common/vdom'

export default {
  data () {
    return {
      searchUrl: getListURL,
      statusOps: statusOps,
      searchParams: {
        categoryLevel: 3,
        categoryAlias: '',
        categoryName: '',
        operatorUserName: '',
        showStatus: undefined

      },
      extraParams: {
        parentId: this.defaultParentId(),
        regionId: this.$route.query.regionId
      },
      headers: [
        {
          prop: 'categoryAlias',
          label: '三级分类名称'
        },
        {
          prop: 'oneCategoryName',
          label: '一级类目'
        },
        {
          prop: 'twoCategoryName',
          label: '二级类目'
        },
        {
          prop: 'threeCategoryName',
          label: '三级类目'
        },
        {
          prop: 'showCategoryPic',
          label: '图标',
          minWidth: '80',
          formatter: (row, prop) => {
            return createImgVNode(this, row, prop, {
              imgUrl: row.categoryPic,
              width: '60px',
              height: '60px'
            })
          }
        },
        {
          prop: 'sort',
          label: '排序'
        },
        {
          prop: 'showStatusStr',
          label: '状态'
        },
        {
          prop: 'operatorUser',
          label: '操作人'
        },
        {
          prop: 'operatorTime',
          label: '操作时间'
        }
      ]
    }
  },

  created () {
    const { title, regionId, sTitle, regionName } = this.$route.query
    this.$setBreadcrumb(`${regionName} > ${sTitle} > ${title}`)
    this.extraParams.regionId = this.$route.query.regionId
  },
  methods: {

    // 返回按钮
    backAction () {
      this.$router.go(-1)
    },

    defaultParentId () {
      let parentId = ''
      if (this.$route.query && this.$route.query.categoryId) {
        parentId = this.$route.query.categoryId
      }
      return parentId
    },

    // 编辑
    edit (row) {
      this.$router.push({
        name: 'goodsCategoryThirdForm',
        query: {
          id: row.id,
          regionName: this.$route.query.regionName,
          sTitle: this.$route.query.sTitle,
          title: this.$route.query.title
        }
      })
    }
  }
}
</script>
